import { BsMicrosoft } from 'react-icons/bs';
import { DiAndroid, DiAws, DiBootstrap, DiCode, DiCss3, DiGithub, DiHtml5, DiJava, DiJavascript1, DiLinux, DiMongodb, DiNodejsSmall, DiOpensource, DiReact } from 'react-icons/di';
import { FiFigma } from 'react-icons/fi';
import { SiExpress, SiTailwindcss } from 'react-icons/si';
import { TbBrandNextjs } from 'react-icons/tb';

var im1 = require("../../assets2/logo1.png");
var im2 = require("../../assets2/logo2.jpeg");
var im3 = require("../../assets2/logo3.png");
var im4 = require("../../assets2/logo4.png");
var im5 = require("../../assets2/logo5.png");
var im6 = require("../../assets2/logo6.png");
var im7 = require("../../assets2/logo7.png");
var im8 = require("../../assets2/logo8.png");
var im9 = require("../../assets2/logo9.jpeg");




const dataArray = [
    {
        language: "ReactJS",
        image: im1,
        description: "ReactJS is my favorite front-end library/framework. I enjoy creating components and building reusable UIs with ReactJS.",
        icon: <DiReact />
    }
    ,
    {
        language: "Java/JavaFX/JavaSwing",
        description: "Java is my first programming language and I have a strong grasp of JavaFX and JavaSwing for creating desktop applications.",
        icon: <DiJava />
    },
    {
        language: "Android",
        description: "I have taken Android development classes and am proficient in developing Android applications using Java or Kotlin.",
        icon: <DiAndroid />
    },
    {
        language: "C++",
        description: "C++ was one of the programming languages I learned at school. I have used it for algorithmic problem solving and competitive programming.",
        icon: <DiCode />
    },
    {
        language: "EJS",
        description: "EJS is a templating language for server-side JavaScript. I have used it for building dynamic web pages with NodeJS.",
        icon: <DiNodejsSmall />
    },
    {
        language: "Material-UI",
        description: "Material-UI is a popular React UI framework that I have used to create responsive and visually appealing web applications.",
        icon: <DiOpensource />
    },
    {
        language: "NodeJS",
        description: "NodeJS is my favorite back-end framework for building scalable and efficient web applications with JavaScript.",
        icon: <DiNodejsSmall />
    },
    {
        language: "Bootstrap",
        description: "Bootstrap is a great CSS framework for building responsive and mobile-first web applications quickly.",
        icon: <DiBootstrap />
    },
    {
        language: "Adobe and Microsoft",
        description: "I am proficient in using design software such as Adobe Photoshop, Illustrator, and Microsoft Office for creating visually appealing designs and presentations.",
        icon: <BsMicrosoft />
    },
    {
        language: "JavaScript",
        description: "JavaScript is my go-to language for creating interactive and dynamic web applications. I am proficient in using modern JavaScript frameworks such as ReactJS and NodeJS.",
        icon: <DiJavascript1 />
    },
    {
        language: "HTML",
        description: "HTML is the backbone of the web and I have a strong understanding of building semantically correct and accessible web pages.",
        icon: <DiHtml5 />
    },
    {
        language: "CSS",
        description: "CSS is essential for styling web pages and I am proficient in using CSS frameworks such as Bootstrap and TailwindCSS.",
        icon: <DiCss3 />
    },
    {
        language: "Express JS",
        description: "ExpressJS is a minimal and flexible NodeJS web application framework that I have used for building RESTful APIs and web applications.",
        icon: <SiExpress />
    },
    {
        language: "GitHub",
        description: "GitHub is my go-to version control platform for managing and collaborating on code with others.",
        icon: <DiGithub />
    },
    {
        language: "AWS",
        description: "I have experience in deploying web applications on Amazon Web Services (AWS) such as EC2, S3, and Lambda for scalable and reliable infrastructure.",
        icon: <DiAws />
    },
    {
        language: "MongoDB",
        description: "MongoDB is a popular NoSQL database that I have used for building scalable and efficient web applications.",
        icon: <DiMongodb />
    },
    {
        language: "Unix/Linux",
        description: "I'm proficient in using Unix/Linux systems and can use command-line interfaces to perform many tasks.",
        icon: <DiLinux />
    },
    {
        language: "Figma",
        description: "Figma is my favorite design tool and I use it to create UI designs and prototypes.",
        icon: <FiFigma />
    }, {
        language: "Tailwind",
        description: "I am proficient in using Tailwind CSS to create responsive and customizable user interfaces for web applications.",
        icon: <SiTailwindcss />
    }, {
        language: "NextJs",
        description: "I have experience using Next.js to build server-rendered React applications with optimized performance and SEO.",
        icon: <TbBrandNextjs />
    }]



export { dataArray };

