
import { Route, Routes } from "react-router-dom";
import './App.css';
//import AboutMe from "./components/AboutMe/Aboutme.jsx";
import About from "./components/About/About.jsx";
import ContactUs from './components/Contact/Contact.jsx';
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import LandingPage from './components/Landingpage/LandingPage.jsx';
import MyProjects from './components/MyProjects/MyProjects';
import Skills from './components/Skills/Skills.jsx';
function App() {
  return (

    <div>

      <Header />
      <div >

        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="aboutme" element={<About />} />
          <Route path="skills" element={<Skills />} />
          <Route path="contactus" element={<ContactUs />} />
          <Route path="myprojects" element={<MyProjects />} />
          <Route path="*" element={<main style={{ padding: "1rem" }}><p>There's nothing here!</p></main>} />
        </Routes>
        <Footer />
      </div>
    </div>
  );
}

export default App;
