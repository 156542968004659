
import emailjs from 'emailjs-com';
import React, { useState } from 'react';
import { AiOutlineArrowDown } from 'react-icons/ai';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const ContactUs = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const apiKey = process.env.REACT_APP_SERVICE_ID;
    const apiKey2 = process.env.REACT_APP_YOUR_TEMPLATE_ID;
    const apiKey3 = process.env.REACT_APP_USER_ID;


    const handleSubmit = (e) => {
        e.preventDefault();

        const templateParams = {
            name,
            email,
            message,
        };
        //send('service_ljs05vf', 'template_3rs46eq', templateParams, '5qWAgO-tw5YKYjlUS')
        emailjs
            .send(apiKey, apiKey2, templateParams, apiKey3)
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setName('');
                setEmail('');
                setMessage('');
                toast.success('Email sent successfully!', { autoClose: 5000 });

            })
            .catch((err) => {
                console.log('FAILED...', err);
                toast.error('Error sending email', { autoClose: false });
            });
    };

    return (
        <div className="bg-pattern h-auto mt-20 hover:drop-shadow-3xl shadow-black  inset-0 transform  hover:scale-105 transition duration-300">
            <h1 className='text-blue-change text-3xl font-black drop-shadow-2xl '>!Keep in touch?</h1>
            <div className="container mx-auto py-16 px-4">
                <form onSubmit={handleSubmit} className="bg-blue-change shadow-md rounded-2xl px-8 pt-6 pb-8">
                    <div className='border-2 border-white shadow appearance-none rounded w-full mb-4'>
                        <h1><spam className='font-bold text-2xl' > Email:</spam><a className='text-white hover:underline hover:text-rowdy-blue visited:text-purple-600' href='mailto:John_youte@student.uml.edu'> John_youte@student.uml.edu</a> <spam className='font-bold text-2xl'> || </spam><a className='text-white hover:underline hover:text-rowdy-blue visited:text-purple-600' href='mailto:johneyoute@gmail.com'>johneyoute@gmail.com</a></h1>
                        <h1><spam className='font-bold text-2xl' > Phone:</spam> <a className='text-white hover:underline hover:text-rowdy-blue visited:text-purple-600' href='tel:617-291-7253'> 617-291-7253</a></h1>
                    </div>
                    <div className='flex flex-row justify-center m-2'>
                        <h1 className='animate-bounce text-2xl text-center'><AiOutlineArrowDown className='text-center' /></h1>
                    </div>
                    <div className="mb-4">
                        <label className="block text-white text-sm font-bold mb-2" htmlFor="name">
                            Name
                        </label>
                        <input
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="name"
                            type="text"
                            placeholder="Enter your name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-white text-sm font-bold mb-2" htmlFor="email">
                            Email
                        </label>
                        <input
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="email"
                            type="email"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="mb-6">
                        <label className="block text-white text-sm font-bold mb-2" htmlFor="message">
                            Message
                        </label>
                        <textarea
                            required
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="message"
                            placeholder="Enter your message"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                    </div>
                    <div className="flex items-center justify-center">
                        <button
                            className="bg-white  hover:bg-black text-blue-change font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            type="submit"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ContactUs;
