import React from "react";
//import Accordion from "../Skills/Accordion.jsx";
import MultiActionAreaCard from "./MultiActionAreaCard.jsx";
import { dataArray } from "./objData.js";
import "./skills.css";




function Skills() {

    return (

        <section className="bg-blue-change flex justify-center ">
            <div className="flex flex-col">
                <div className=""><h1 className="text-6xl text-white m-6">Skills</h1></div>
                <div className="grid grid-cols-2 gap-4 lg:gap-10 p-4 lg:grid-cols-3">
                    {dataArray.map((element, index) => {
                        return (
                            <MultiActionAreaCard key={index} icon={element.icon} title={element.language} text={element.description} />
                        )
                    })

                    }
                </div>
            </div>
        </section>

    )
}


export default Skills;