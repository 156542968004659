import React from "react";
import ProjectCard from "../MyProjects/ProjectCard.jsx";
import "./MyProject.css";


function MyProjects(props) {

    return (
        <div className="h-auto pt-10 bg-blue-change">
            <div className="text-white font-black drop-shadow-2xl text-4xl">
                Explore my projects
            </div>
            <ProjectCard />
        </div>

    )
}

export default MyProjects;