import { faCode, faContactBook, faPeopleLine } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from "react";
import { Container, Nav, Navbar } from 'react-bootstrap';
import { FaLaptopCode } from 'react-icons/fa';



function Header() {

    return (

        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
                <Navbar.Brand href="/"><FaLaptopCode className='text-5xl' /></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link href="/AboutMe"><FontAwesomeIcon icon={faPeopleLine} />AboutME</Nav.Link>
                        <Nav.Link href="/Skills"><FontAwesomeIcon icon={faCode} />Skills </Nav.Link>
                        <Nav.Link href="/ContactUs"><FontAwesomeIcon icon={faContactBook} /> Contact Me</Nav.Link>
                        <Nav.Link href="/MyProjects"><FontAwesomeIcon icon={faContactBook} /> My Projects</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    );

}

export default Header;