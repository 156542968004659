import React from "react";



function Footer() {
    const d = new Date();
    let year = d.getFullYear();

    return (

        <div className=" bg-blue-change h-auto">

            <footer className="p-6">
                <p className="m-8 text-lg font-black">Copyright @ John E. Youte {year}</p>
            </footer>

        </div>

    );
}


export default Footer;