
import React from "react";
import john from "../../assets/avatar3.png";
import "./aboutme.css";


function AbooutMe() {
    var birthday = '1996-02-21';
    const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)
    console.log(getAge(birthday));
    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 bg-white h-screen m-2 inset-0 transform  hover:scale-105 transition duration-700">
            <div className="flex justify-items-center">
                <img className="rounded-full " src={john} alt="john" />
            </div>
            <div className="bg-blue-change rounded-3xl overflow-y-auto w-auto  flex justify-center shadow-blue-change lg:m-16 hover:shadow-2xl">
                <div className="m-10">
                    <div className="m-10"><h1 className="text-2xl font-black text-white ">About ME</h1></div>
                    <div className="text-left lg:text-center text-lg text-white p-2">
                        <h1> I am highly passionate about software development in all its forms. My extensive knowledge of programming languages, database systems, and software engineering principles, coupled with my friendly and hardworking personality, make me an ideal candidate for any software development role. I possess excellent problem-solving skills and a keen eye for detail, which enables me to develop highly efficient and effective software solutions. My ability to work collaboratively with others, coupled with my strong communication skills, ensures that I am able to thrive in team environments and deliver projects on time and within budget.My passion for software development began at a young age, and I have spent countless hours learning and mastering different programming languages, including Java, c and C++, javascript. This has enabled me to develop a deep understanding of software development principles and best practices, which I am able to apply in a practical setting.As a hardworking and dedicated individual, I am committed to delivering high-quality software solutions that meet the needs of end-users. I am also eager to continue learning and growing in my field.</h1>
                    </div>
                </div>

            </div>
        </div>
    );
}

export default AbooutMe;
