
export default function MultiActionAreaCard(props) {
    return (
        <div className="bg-black hover:bg-blue-change bg-opacity-25 hover:drop-shadow-2xl text-white h-auto  w-auto flex flex-col p-2 lg:p-6 rounded-3xl  overflow-hidden inset-0 transform hover:rotate-6 hover:scale-105 transition duration-300">
            <div className='flex justify-center bg-white m-1 rounded-full '>
                <h1 className="text-5xl m-2 text-blue-change">{props.icon}</h1>
            </div>
            <div className='flex m-1 justify-center'>
                <h1>{props.title}</h1>
            </div>
            <div className='justify-center m-1 hidden lg:flex'>
                <h1 className='text-lg'>{props.text}</h1>
            </div>

        </div>
    );
}

