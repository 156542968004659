import React, { useState } from "react";
import { Link } from "react-router-dom";
// import backgroundimage1 from "../../assets/background.jpg";
import { AiFillFilePdf, AiFillFolderOpen, AiFillGithub, AiFillLinkedin } from 'react-icons/ai';
import resume from "../../assets/resume.pdf";
import About from "../About/About.jsx";
import ContactUs from '../Contact/Contact.jsx';
import Skills from '../Skills/Skills.jsx';
import "./landingPage.css";

function Body() {
    var image = require("../../assets/avatar1.png");
    const image2 = require('../../assets/avatar3.png');
    const [currImg, setImg] = useState(image);
    // const [click, setclick] = useState("");

    // function myOnclick(event) {
    //     var whichOne = event.target.id;
    //     if (whichOne === '1') {

    //     }
    // }
    function myfunction() {
        setImg(image)
    }
    function myfunction1() {
        setImg(image)
    }
    return (
        <div className='rounded-3xl' >

            <div className="inset-0 transform h-screen  w-auto justify-center bg-blue-change bg-opacity-50 lg:bg-fixed bg-no-repeat bg-cover bg-center flex flex-col"  >
                {/* style={{ backgroundImage: `url(${"https://images.pexels.com/photos/370799/pexels-photo-370799.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"})` }} */}
                <div className='flex justify-center m-4'>
                    <img className=' lg:w-96 hover:scale-105 transition duration-300 drop-shadow-2xl shadow-2xl rounded-xl' src={currImg} onMouseOut={myfunction1} onMouseEnter={myfunction} alt="bitmoji" />
                </div>
                <div className=''>
                    <div className='flex justify-center'>
                        <h1 className="animate-bounce text-4xl lg:text-8xl text-white border-blue-change drop-shadow-2xl shadow-2xl rounded-xl stroke-gray-950 stroke-6 font-black m-4 p-2 ">John E. Youte</h1>
                    </div>
                    <div className='flex justify-center'>
                        <h1 id="typing" className="typing-demo text-4xl text-white bg-black rounded-3xl font-black m-4 p-2 drop-shadow-2xl shadow-2xl">@Software Eng..............</h1>
                    </div>
                </div>
                <div className="scroll flex flex-row justify-center m-10 ">
                    <div className="flex flex-col h-auto justify-center mr-2 ">
                        <h3>LinkedIn</h3>
                        <form target="_blank" action="https://www.linkedin.com/in/john-youte-2162391b8">
                            <button className="bg-blue-change hover:bg-black text-white font-semibold py-2 px-4 m-1 rounded-md" ><AiFillLinkedin className="text-3xl" /></button>
                        </form>
                    </div>
                    <div className="flex flex-col justify-center mr-2">
                        <h3>GitHub</h3>
                        <form target="_blank" action="https://github.com/johnbostonma">
                            <button className="bg-blue-change hover:bg-black text-white font-semibold py-2 px-4 m-2 rounded-md" ><AiFillGithub className="text-3xl" /></button>
                        </form>
                    </div>
                    <div className="flex flex-col justify-center mr-2">
                        <h3>Projects</h3>
                        <Link className="bg-blue-change hover:bg-black text-white font-semibold py-2 px-4 m-2 rounded-md" to="/myprojects"><AiFillFolderOpen className="text-3xl" /></Link>
                    </div>
                    <div className="flex flex-col justify-center mr-2">
                        <h3>Resume</h3>
                        <form target="_blank" onSubmit="" id="SampleForm">
                            <button type="submit" onClick={() => { window.open(resume); }} className="bg-blue-change hover:bg-black text-white font-semibold py-2 px-4 m-2 rounded-md">
                                <AiFillFilePdf className="text-3xl" />
                            </button>
                        </form>
                    </div>
                </div>
            </div>
            <div>
                <Skills />
                <About />
                <ContactUs />
            </div>

        </div>
    );

}


export default Body;